import { PicRightOutlined } from '@ant-design/icons'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { add, getOne, update } from '@/services/project'
import { useData } from '@/utils/hooks'
import importCompont from '@/utils/importCompont'

import useAppContext from './useAppContext'

const Project = importCompont(() => import('./Index/index'))
const ProjectEdit = importCompont(() => import('@pn/components/src/pages/Projects/Edit'))
const Stock = importCompont(() => import('./Stock'))

const t = (str) => str

const Layout = () => {
    const [data, setData] = useData({ pageNo: 1 })
    let context = { context: data, dispatch: setData }
    return (
        <useAppContext.Provider value={context}>
            <Outlet />
        </useAppContext.Provider>
    )
}

const routes = {
    path: '/ucenter/projects',
    name: t('项目管理'),
    auth: 'seller',
    icon: <PicRightOutlined />,
    element: <Layout />,
    children: [
        {
            path: '/ucenter/projects',
            name: t('项目列表'),
            hideInMenu: true,
            element: <Project />,
        },
        {
            path: '/ucenter/projects/add',
            name: t('新增项目'),
            hideInMenu: true,
            element: <ProjectEdit postFetch={add} getOne={getOne} role="seller" action="add" />,
        },
        {
            path: '/ucenter/projects/detail/:id',
            name: t('编辑详情'),
            hideInMenu: true,
            element: <ProjectEdit postFetch={update} getOne={getOne} role="seller" />,
        },
        {
            path: '/ucenter/projects/stock/:id',
            name: t('库存管理'),
            hideInMenu: true,
            element: <Stock />,
        },
        { path: '', element: <Navigate replace to={{ pathname: '/ucenter/projects' }} /> },
    ],
}

export default routes
