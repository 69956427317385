// import { makeData } from '@pn/components/src/makeData'
import { message } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HashRouter } from 'react-router-dom'

import { getUserInfo } from '@/services'
import useAppContext from '@/useAppContext'
import { initCycleMenu } from '@/utils/initCycleMenu'

import Router from './pages/routes'
import { routes } from './pages/Ucenter'

// console.log(JSON.stringify(makeData(40)))

const App = () => {
    const { t } = useTranslation()
    const { context, dispatch } = useAppContext.getContext()
    const { storageKey, language, menus = [] } = context
    useEffect(() => {
        document.title = t('碳中和网站标题')
    }, [language])
    useEffect(() => {
        if (!storageKey) {
            return dispatch({ loading: false, isLogin: false })
        }
        dispatch({ loading: true, isLogin: false })
        getUserInfo().then((res) => {
            if (res.success) {
                let role = res.data.sellerStatus ? 'seller' : 'buyer'
                let menus = initCycleMenu(routes, [role])
                dispatch({ user: res.data, loading: false, isLogin: true, menus, role })
            } else {
                localStorage.removeItem('storageKey')
                sessionStorage.removeItem('storageKey')
                dispatch({ storageKey: undefined, loading: false, isLogin: false })
            }
        })
        let out = false
        const logOut = () => {
            if (!out) {
                message.warning(t('token无效或已过期，请重新登录'))
            }
            out = true
            localStorage.removeItem('storageKey')
            sessionStorage.removeItem('storageKey')
            dispatch({ storageKey: undefined, loading: false, isLogin: false })
        }
        window.addEventListener('tokenOut', logOut, false)
        return () => window.removeEventListener('tokenOut', logOut, false)
    }, [storageKey])
    return (
        <HashRouter>
            <Router routes={menus} />
        </HashRouter>
    )
}

export default App
