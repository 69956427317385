import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import useAppContext from '@/useAppContext'
import importCompont from '@/utils/importCompont'

const Layout = importCompont(() => import('./Layout'))
const LayoutUcenter = importCompont(() => import('./Ucenter/Layout'))
const Home = importCompont(() => import('./Home'))
const Project = importCompont(() => import('./Project'))
const Login = importCompont(() => import('./Login'))
const Register = importCompont(() => import('./Register'))
const Repassword = importCompont(() => import('./Repassword'))

const Page404 = () => {
    const { context } = useAppContext.getContext()
    const { loading } = context
    if (loading) {
        return <div />
    }
    return <Navigate replace to={{ pathname: '/' }} />
}

const Router = ({ routes }) => {
    const router = useRoutes([
        // { path: '/', element: <Home /> },
        { path: '/login', element: <Login /> },
        { path: '/register', element: <Register /> },
        { path: '/repassword', element: <Repassword /> },
        // { path: '/project/:id', element: <Project /> },
        { path: '/ucenter', element: <LayoutUcenter />, children: routes },
        {
            path: '/',
            element: <Layout />,
            children: [
                { path: '/', element: <Home /> },
                { path: '/project/:id', element: <Project /> },
            ],
        },
        { path: '*', element: <Page404 /> },
    ])
    return router
}

export default Router
