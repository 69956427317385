
export const initCycleMenu = (lists, perms, admin = false) => {
    let list = lists
    if (admin) { return list }
    let menus = list.filter(d => {
        if (!d.auth || d.auth == "*") { return true }
        return perms.includes(d.auth);
    });
    menus = menus.map(m => {
        if (m.routes) {
            let routes = initCycleMenu(m.routes, perms);
            if (routes.length == 0) {
                return null
            }
            return { ...m, routes }
        }
        return m;
    }).filter(d => d);
    return menus;
};
