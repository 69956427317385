import { DollarOutlined, HomeOutlined, SettingOutlined, TagOutlined } from '@ant-design/icons'
import React from 'react'
import { Navigate } from 'react-router-dom'

import { getProjectDetail } from '@/services'
import { getOne } from '@/services/order'
import useAppContext from '@/useAppContext'
import importCompont from '@/utils/importCompont'

import project from './Project'

const Home = importCompont(() => import('./Dashboard'))
const Order = importCompont(() => import('./Order'))
const OrderBuyer = importCompont(() => import('./Order/OrderBuyer'))
const OrderDetail = importCompont(() => import('@pn/components/src/pages/Order/Detail'))
const BillIndex = importCompont(() => import('./Bill'))
const Bill = importCompont(() => import('./Bill/Bill'))
const Commission = importCompont(() => import('./Bill/Commission'))
const Withdraw = importCompont(() => import('./Bill/Withdraw'))
const Setting = importCompont(() => import('./Setting'))
const ApplyFor = importCompont(() => import('./ApplyFor'))
// const Info = importCompont(() => import('./Info'))

const t = (str) => str

const OrderD = () => {
    const { context } = useAppContext.getContext()
    const { user } = context
    return <OrderDetail getOne={getOne} getProject={getProjectDetail} owId={user.id} />
}

export const routes = [
    {
        path: '/ucenter',
        name: t('首页'),
        auth: 'seller',
        icon: <HomeOutlined />,
        element: <Home />,
    },
    {
        path: '/ucenter',
        name: t('我的订单'),
        auth: 'buyer',
        icon: <TagOutlined />,
        element: <OrderBuyer />,
    },
    {
        path: '/ucenter/order/buyer',
        name: t('我的订单'),
        auth: 'buyer',
        hideInMenu: true,
        element: <Navigate replace to={{ pathname: '/ucenter' }} />,
    },
    {
        path: '/ucenter/order/:id',
        name: t('订单详情'),
        auth: 'buyer',
        hideInMenu: true,
        element: <OrderD />,
    },
    {
        path: '/ucenter/order',
        name: t('订单管理'),
        auth: 'seller',
        icon: <TagOutlined />,
        children: [
            {
                path: '/ucenter/order/seller',
                name: t('卖出的'),
                element: <Order />,
            },
            {
                path: '/ucenter/order/buyer',
                name: t('买进的'),
                element: <OrderBuyer />,
            },
            {
                path: '/ucenter/order/:id',
                name: t('订单详情'),
                hideInMenu: true,
                element: <OrderD />,
            },
            { path: '', element: <Navigate replace to={{ pathname: '/ucenter/order/seller' }} /> },
        ],
    },
    project,
    {
        path: '/ucenter/bill',
        name: t('账单管理'),
        icon: <DollarOutlined />,
        children: [
            {
                path: '/ucenter/bill/list',
                name: t('账户信息'),
                element: <BillIndex />,
            },
            {
                path: '/ucenter/bill/logs',
                name: t('账单记录'),
                element: <Bill />,
            },
            {
                path: '/ucenter/bill/commission',
                name: t('我的佣金'),
                element: <Commission />,
            },
            {
                path: '/ucenter/bill/withdraw',
                name: t('提现记录'),
                element: <Withdraw />,
            },
            { path: '', element: <Navigate replace to={{ pathname: '/ucenter/bill/list' }} /> },
        ],
    },
    {
        path: '/ucenter/setting',
        name: t('收银设置'),
        element: <Setting />,
        icon: <SettingOutlined />,
    },
    {
        path: '/ucenter/applyfor',
        name: t('成为卖家'),
        auth: 'buyer',
        element: <ApplyFor />,
        icon: <SettingOutlined />,
    },
    // {
    //     path: '/ucenter/info',
    //     name: t('个人信息'),
    //     element: <Info />,
    //     icon: <UserOutlined />,
    // },
    {
        path: '*',
        element: <Navigate replace to={{ pathname: '/ucenter' }} />,
    },
]
