import fetch from "@/utils/fetch";

// 获取列表
export const getList = async (params) => fetch.get('/v1/m_2/project/list', { params });

// 详情
export const getOne: any = (params) => fetch('/v1/m_2/project/detail', { params });

// 新增
export const add: any = (data) => fetch.post('/v1/m_2/add', data);

// 编辑
export const update = (data) => fetch.post('/v1/m_2/edit', data);
