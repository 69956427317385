import fetch from "@/utils/fetch";

// 获取列表
export const getList = async (params) => fetch.get('/v1/m_2/order/list', { params });

// 详情
export const getOne: any = (params) => fetch('/v1/m_3/order/detail', { params });

// 发货
export const update = (data) => fetch.post('/v1/m_2/order/shipments', data);

// 取消
export const del = (data) => fetch.post('/v1/m_2/order/cancel', data);
