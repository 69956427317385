import basefetch from '@/utils/basefetch'
import fetch from '@/utils/fetch'

// 登录
export const login: any = async (data) => fetch.post("/v1/noRequired/login", data)

export const register: any = async (data) => fetch.post("/v1/noRequired/register", data)

// 获取用户信息
export const getUserInfo: any = async (data) => basefetch.get("/v1/m_3/my/info", data)

export const getUserInfo2: any = async () => fetch.get("/v1/m_3/my/info")

// 修改用户信息
export const postUserInfo: any = async (data) => fetch.post("/v1/m_3/update/info", data)

// 获取首页统计
export const getIndexPanel: any = async () => fetch.get("/v1/m_3/index/panel")

// 发送验证码邮件
export const postCaptcha: any = async (data) => fetch.post("/v1/noRequired/register/email", data)

// 发送验证码邮件找回密码
export const postCaptcha2: any = async (data) => fetch.post("/v1/noRequired/reset/email", data)

// 找回密码
export const rePassword: any = async (data) => fetch.post("/v1/noRequired/reset/pwd", data)

// 获取项目列表
// @ts-ignore
export const getProjectList = async (params) => fetch.get('/v1/noRequired/project/list', { params });
// 项目详情
export const getProjectDetail = async (params) => fetch.get('/v1/noRequired/project/detail', { params });

export const getProjectDetailYear = async (params) => fetch.get('/v1/noRequired/project/year/list', { params });

// 账号详情
export const getAccountOne: any = (params) => fetch('https://www.tomlb.com/api/koa/monk/account/get', { params });

// 账号升级
export const postAccountUpgrade: any = async (data) => fetch.post("/v1/user/m_1/accountUpgrade", data)

export const uploadfile: any = async (file) => {
    let formData = new FormData();
    formData.append("file", file)
    return basefetch({
        url: 'v1/api/file/upload',
        method: "post",
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }).then(res => {
        return res.data[0]
    })
}
