import axios from "axios";
const { baseUrl } = window.AppConfig
const tr = (str) => str == "zh-CN" ? 'zh' : str
const netfetch = axios.create({
    baseURL: baseUrl,
    timeout: 15000,
    headers: {
        "Content-Type": "application/json"
    }
});

// 添加请求拦截器
netfetch.interceptors.request.use((config) => {
    // @ts-ignore
    config.headers = {
        token: sessionStorage.getItem("storageKey") || localStorage.getItem("storageKey"),
        language: tr(localStorage.getItem("language") || "en-US"),
        ...config.headers
    };
    return config;
}, (error) => {
    console.log("before", error)
    return {
        success: false,
    }
});

// 添加响应拦截器
netfetch.interceptors.response.use((response) => {
    if (response.status == 200) {
        if (["10010", "10011"].includes(response.data.code)) {
            const event = new CustomEvent("tokenOut")
            window.dispatchEvent(event);
            // throw response.data.msg
        }
        return {
            success: true,
            ...response.data
        }
    }

    return {
        ...response.data,
        success: false,
    }
}, (error) => {
    console.log(error);
    return {
        message: error.message,
        success: false,
    }
});

export default netfetch;
