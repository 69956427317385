import axios from "axios";
import qs from 'qs';

const langs = {
    "zh-CN": 'zh',
    "en-US": 'en',
    "fr_FR": 'fr'
}

const tr = (str) => langs[str] || str

const { baseUrl } = window.AppConfig
const netfetch = axios.create({
    baseURL: baseUrl,
    timeout: 15000,
    headers: {
        "Content-Type": "application/json"
    },
    paramsSerializer: {
        serialize: params => qs.stringify(params, { arrayFormat: 'repeat' })
    }
});

// 添加请求拦截器
netfetch.interceptors.request.use((config) => {
    // @ts-ignore
    config.headers = {
        token: sessionStorage.getItem("storageKey") || localStorage.getItem("storageKey"),
        language: tr(localStorage.getItem("language") || "en-US"),
        ...config.headers
    };
    return config;
}, (error) => {
    console.log("before", error)
    throw error
});

// 添加响应拦截器
netfetch.interceptors.response.use((response) => {
    if (response.data.code == 200) {
        return response.data.data;
    }
    if (["10010", "10011"].includes(response.data.code)) {
        const event = new CustomEvent("tokenOut")
        window.dispatchEvent(event);
    }
    throw response.data.msg
}, (error) => {
    throw error.message || error
});

export default netfetch;
