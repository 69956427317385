// @ts-nocheck
import { locales } from '@pn/components/src/locale'
import { App, Button, ConfigProvider, Space } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'

import useAppContext from '@/useAppContext'
import { useData } from '@/utils/hooks'

import Apps from './App'

import './main.less'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60, // 数据缓存时间 默认 1000 * 60 * 5 5分钟
            staleTime: 10 * 1, // 重新获取数据的时间间隔 默认0
            retry: 2, // 失败重试次数 默认 3次
            refetchOnWindowFocus: false, // 窗口获得焦点时重新获取数据
            // keepPreviousData: false,
            //   retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
        },
    },
})

const Root = () => {
    const initValue = {
        storageKey: sessionStorage.getItem('storageKey') || localStorage.getItem('storageKey'),
        user: {},
        menus: [],
        isLogin: false,
        loading: true,
        font: 'loading',
        language: localStorage.getItem('language') || 'en-US',
        autoPrintReady: false,
        printers: [],
    }
    const dataRef = useRef<any>({})
    const [data, setData] = useData(initValue)
    dataRef.current = data
    let context = { context: data, dispatch: setData }
    useEffect(() => {
        dayjs.locale(data.language)
    }, [data.language])
    return (
        <ConfigProvider
            locale={locales[data.language]}
            theme={{
                token: {
                    // Seed Token，影响范围大
                    colorPrimary: '#212E77',
                    borderRadius: 2,
                    // 派生变量，影响范围小
                    //   colorBgContainer: '#f6ffed',
                },
            }}
        >
            <QueryClientProvider client={queryClient}>
                <useAppContext.Provider value={context}>
                    <Apps />
                </useAppContext.Provider>
            </QueryClientProvider>
        </ConfigProvider>
    )
}

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')!).render(<Root />)
