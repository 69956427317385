import React, { createContext, useContext } from "react";

const context = createContext<any>({});

export const getContext = () => useContext(context)

export const Provider = ({ value = {}, children }) => {
    return <context.Provider value={value}>
        {children}
    </context.Provider>
}

export default {
    getContext,
    Provider
}